<template>
    <div class="wrapper">
        <div class="row">
            <div class="offset-3 col-lg-6">
                <div class="info-card">
                    <h4>Google Auth Redirect</h4>
                    <Alerts :_success="success" :_error="error"></Alerts>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Alerts from "../Helpers/Alerts";

export default {
    name: "Google",
    components: {Alerts},
    props: ['id'],
    mounted() {
        this.success = "Connection to Google successful. Connecting to Sabasi..."
        this.$http.get(`/api/auth/google/${this.id}`).then(response => {
            let user = response.data.data.user;
            localStorage.setItem(process.env.VUE_APP_NAME + "_token", response.data.data.token);
            localStorage.setItem(process.env.VUE_APP_NAME + "_user", JSON.stringify(user));
            this.success = `Connection successful. Welcome ${user.name} Redirecting to your dashboard...`
            setTimeout(() => (window.location.href = '/dashboard'), 1000)

        }).catch(error => {
            this.showError(error)
        }).then(() => {
            this.wait = false
        })
    }
}
</script>

<style scoped>

</style>